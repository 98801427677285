import { Section, Button, H5, Anchor } from "@dnb/eufemia"
import React, { useEffect, useContext, useState } from "react"
import "../css/main.css"
import { Logo } from "@dnb/eufemia/components"
import Person from "../images/icons/person.png"
import { LOGOUT_URL } from "../config"
import ErrorModal from "./ErrorModal"
import { fetchCurrentUser, User } from "../api/User"
import AuthContext from "../auth/context"
import { useQuery, useQueryClient } from "react-query"

type Props = {
  children: React.ReactNode
}

const Layout = ({ children }: Props) => {
  const [name, setName] = useState<string | null>(null)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setName(localStorage.getItem(`user_name`))
    }
  }, [])
  /*
  useEffect(() => {
    if (user.isError && name) {
      logout()
    }
  }, [user])*/

  const logout = () => {
    sessionStorage.clear()
    if (typeof window !== `undefined`) {
      localStorage.removeItem(`user_name`)
    }
    window.location.assign(LOGOUT_URL)
  }

  return (
    <div className="Layout">
      <div className="Content-Wrap">
        <noscript>
          <div className="Content-Wrap__NoScriptWarning">
            Denne nettsiden krever JavaScript for å fungere. Vennligst endre innstillingene i din nettleser.
          </div>
        </noscript>
        <Section className="Header">
          <div className="Content">
            <div className="Content__Logo">
              <Anchor href="." className="dnb-anchor dnb-anchor--no-style">
                <span style={{ fontSize: `1rem` }}>
                  <Logo size="32" color="sea-green" />
                </span>
                <span>Betalingskort</span>
              </Anchor>
            </div>
            <div className="Block-Right">
              {name ? (
                <>
                  <div className="Block-Right__User">
                    <img src={Person} />
                    <div>{name}</div>
                  </div>
                  <Button
                    className="Block-Right__Button"
                    id="logout-button"
                    text="Logg ut"
                    variant="secondary"
                    onClick={logout}
                  />
                </>
              ) : null}
            </div>
          </div>
        </Section>
        <Section style_type="white">
          <main className="Main">{children}</main>
        </Section>
      </div>
      <footer className="Footer">
        <div className="Content">
          <span style={{ fontSize: `1rem` }}>
            <Logo size="32" color="white" />
          </span>
          <H5 top="medium">Denne siden eies og driftes av DNB Bank ASA © 2021 DNB</H5>
        </div>
      </footer>
      <ErrorModal />
    </div>
  )
}

export default Layout
