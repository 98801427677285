import { navigate, PageProps } from "gatsby"
import { parse, ParsedQuery } from "query-string"
import { ProgressIndicator } from "@dnb/eufemia/components"
import React, { useState } from "react"
import ErrorModal from "../components/ErrorModal"

import Layout from "../components/Layout"

const LogoutCompletePage = ({ location }: PageProps) => {
  const [error_, setError] = useState(false)
  React.useEffect(() => {
    const error = parse(location.hash.replace(/^#/, ``))
    if (Object.keys(error).length > 0) {
      setError(true)
    } else {
      setError(false)
      navigate(`/`)
    }
  }, [])
  return (
    <Layout>
      {error_ ? (
        <ErrorModal />
      ) : (
        <div style={{ margin: `16rem auto`, width: `4rem` }}>
          <ProgressIndicator size="large" />
        </div>
      )}
    </Layout>
  )
}

export default LogoutCompletePage
